/* eslint-disable import/no-mutable-exports */
let env = process.env.REACT_APP_ENV;
let envValues = {
  PORT: 80,
  REACT_APP_API_ENDPOINT: 'http://localhost:4075/v1',
  REACT_APP_API_FRONT_END: 'http://localhost:4075',
  REACT_APP_API_PERMISSION_DENIED: 'http://localhost:4075/permission',
  REACT_APP_SHOW_COOKIE_BOX: false,
};
if (!env)
  if (typeof window !== 'undefined') {
    switch (window.location.host) {
      case 'manage-cares.iscriptsdemo.com':
        env = 'staging';
        break;
      case 'manage-caresapp.iscriptsdemo.com':
        env = 'staging';
        break;
      case 'manage.caresystems.app':
        env = 'production';
        break;
      default:
        env = 'development';
    }
  } else {
    env = 'development';
  }
switch (env) {
  case 'staging':
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'https://cares-api.iscriptsdemo.com/v1',
      REACT_APP_API_FRONT_END: 'http://manage-cares.iscriptsdemo.com/',
      REACT_APP_API_PERMISSION_DENIED: 'http://manage-cares.iscriptsdemo.com/permission',
      REACT_APP_SHOW_COOKIE_BOX: false,
    };
    break;
  case 'production':
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'https://api.caresystems.app/v1',
      REACT_APP_API_FRONT_END: 'https://manage.caresystems.app/',
      REACT_APP_API_PERMISSION_DENIED: 'https://manage.caresystems.app/permission',
      REACT_APP_SHOW_COOKIE_BOX: false,
    };
    break;
  case 'development':
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'http://localhost:4075/v1',
      REACT_APP_API_FRONT_END: 'http://localhost:4075',
      REACT_APP_API_PERMISSION_DENIED: 'http://localhost:4075/permission',
      REACT_APP_SHOW_COOKIE_BOX: false,
    };
    break;
  default:
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'http://localhost:4075/v1',
      REACT_APP_API_FRONT_END: 'http://localhost:3000',
      REACT_APP_API_PERMISSION_DENIED: 'http://localhost:3000/permission',
      REACT_APP_SHOW_COOKIE_BOX: false,
    };
    break;
}

export default envValues;
