import envValues from '../enviornment';

export const SERVER_URL = `${envValues.REACT_APP_API_ENDPOINT}/auth`;
export const SERVER_BASE_URL = envValues.REACT_APP_API_ENDPOINT;
export const ERROR_MESSAGE = 'Api Fetch Error!';
export const ENCRYPT_SECERET_KEY = 'armia-secret-key@123';
export const REACT_APP_API_POST_USER = `${envValues.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${envValues.REACT_APP_API_ENDPOINT}/user?`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${envValues.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${envValues.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_SKILLS = `${envValues.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/roles/select`;

export const REACT_APP_API_SPACE_TYPE_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/spacecategory/category/select`;
export const REACT_APP_API_EQUIPMENT_CATEGORY_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/equipmentcategory/category/select`;
export const REACT_APP_API_EQUIPMENT_SUB_CATEGORY_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/equipmentsubcategory/category/select`;
export const REACT_APP_API_SERVICE_CATEGORY_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/servicecategory/category/select`;
export const REACT_APP_API_SERVICE_ROLE_TYPE_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/appuser/role-type/select`;
export const REACT_APP_API_SERVICE_APPUSER_TYPE_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/appuser/appuser-type/select`;

export const REACT_APP_API_ADMIN_ENDPOINT = `${envValues.REACT_APP_API_ENDPOINT}/admin`;
export const REACT_APP_API_FETCH_APPUSER = `${envValues.REACT_APP_API_ENDPOINT}/appuser?`;
export const REACT_APP_API_FETCH_APPUSER_BY_ID = `${envValues.REACT_APP_API_ENDPOINT}/appuser`;
export const REACT_APP_API_FETCH_SERVICE_PROVIDERS = `${envValues.REACT_APP_API_ENDPOINT}/serviceprovider?`;
export const REACT_APP_API_FETCH_PROPERTY_OWNERS = `${envValues.REACT_APP_API_ENDPOINT}/propertyowner?`;
export const REACT_APP_API_FETCH_JOB_REQUEST = `${envValues.REACT_APP_API_ENDPOINT}/jobrequest?`;
export const REACT_APP_API_FETCH_TASK = `${envValues.REACT_APP_API_ENDPOINT}/task?`;
export const REACT_APP_API_FETCH_PROPERTY = `${envValues.REACT_APP_API_ENDPOINT}/property?`;
export const REACT_APP_API_FETCH_CATEGORY = `${envValues.REACT_APP_API_ENDPOINT}/category?`;
export const REACT_APP_API_FETCH_SPACE_CATEGORY = `${envValues.REACT_APP_API_ENDPOINT}/spacecategory?`;
export const REACT_APP_API_FETCH_EQUIPMENT_CATEGORY = `${envValues.REACT_APP_API_ENDPOINT}/equipmentcategory?`;
export const REACT_APP_API_FETCH_EQUIPMENT_SUB_CATEGORY = `${envValues.REACT_APP_API_ENDPOINT}/equipmentsubcategory?`;
export const REACT_APP_API_FETCH_EQUIPMENT = `${envValues.REACT_APP_API_ENDPOINT}/equipment?`;
export const REACT_APP_API_FETCH_SERVICE_CATEGORY = `${envValues.REACT_APP_API_ENDPOINT}/servicecategory?`;
export const REACT_APP_API_FETCH_SERVICE_SUB_CATEGORY = `${envValues.REACT_APP_API_ENDPOINT}/servicesubcategory?`;
export const REACT_APP_API_FETCH_REQUESTED_USERS = `${envValues.REACT_APP_API_ENDPOINT}/certificationmanagement/requested?`;
export const REACT_APP_API_FETCH_ONGOING_USERS = `${envValues.REACT_APP_API_ENDPOINT}/certificationmanagement/ongoing?`;
export const REACT_APP_API_FETCH_COMPLETED_USERS = `${envValues.REACT_APP_API_ENDPOINT}/certificationmanagement/completed?`;
export const REACT_APP_API_FETCH_UTILITY = `${envValues.REACT_APP_API_ENDPOINT}/utilitycategory?`;
export const REACT_APP_API_FETCH_ROOM_DIRECTION = `${envValues.REACT_APP_API_ENDPOINT}/roomdirection?`;
export const REACT_APP_API_FETCH_ROOM_ORIENTATION = `${envValues.REACT_APP_API_ENDPOINT}/roomorientation?`;
export const REACT_APP_API_FETCH_ROOM_FLOORS = `${envValues.REACT_APP_API_ENDPOINT}/roomfloor?`;
export const REACT_APP_API_FETCH_ROOM_WALL = `${envValues.REACT_APP_API_ENDPOINT}/roomwall?`;
export const REACT_APP_API_FETCH_ROOM_ROOF = `${envValues.REACT_APP_API_ENDPOINT}/roomroof?`;
export const REACT_APP_API_FETCH_ACTIVE_JOBS = `${envValues.REACT_APP_API_ENDPOINT}/task/active-jobs?`;
export const REACT_APP_API_FETCH_PROPERTY_CATEGORY = `${envValues.REACT_APP_API_ENDPOINT}/property/select/property-category`;
export const REACT_APP_API_FETCH_SUBSCRIPTION = `${envValues.REACT_APP_API_ENDPOINT}/subscription?`;
export const REACT_APP_API_FETCH_MESSAGES = `${envValues.REACT_APP_API_ENDPOINT}/messages?`;
export const REACT_APP_API_FETCH_MESSAGES_DETAILS = `${envValues.REACT_APP_API_ENDPOINT}/messages`;
export const REACT_APP_API_FETCH_BROADCAST = `${envValues.REACT_APP_API_ENDPOINT}/broadcast?`;
export const REACT_APP_API_FETCH_FLOOR_MANAGEMENT = `${envValues.REACT_APP_API_ENDPOINT}/floormanagement?`;
export const REACT_APP_API_FETCH_WAITLIST = `${envValues.REACT_APP_API_ENDPOINT}/waitlist?`;
