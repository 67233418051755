import React, { Suspense, lazy } from 'react';
import { SpinnerDotted } from 'spinners-react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getCookies, removeCookies } from '../hooks/useCookies';
import OuterLayout from '../layouts/outerLayout';
import Innerlayout from '../layouts/innerLayout';
import SettingsLayout from '../pages/settings/settingsLayout';
import ProfileLayout from '../pages/profile/profileLayout';

const ViewUsers = lazy(() => import('../pages/users/viewUser'));
const UserIndex = lazy(() => import('../pages/users/index'));
const UserCreate = lazy(() => import('../pages/users/createUser'));
const Preferences = lazy(() => import('../pages/settings/preferences'));
const CreateRoles = lazy(() => import('../pages/roles/createRoles'));
const RolesIndex = lazy(() => import('../pages/roles/index'));
const EditRoles = lazy(() => import('../pages/roles/editRoles'));
const SettingsApi = lazy(() => import('../pages/settings/SettingsApiPage'));
const PageNotFound = lazy(() => import('../pages/404/pageNotFound'));
const PermissionDenied = lazy(() => import('../pages/permission/permission'));
const Settings = lazy(() => import('../pages/settings/settings'));
const Login = lazy(() => import('../pages/logIn/logIn'));
const UserVerification = lazy(() => import('../pages/verify/userVerfication'));
const Register = lazy(() => import('../pages/register/register'));
const Forgetpassword = lazy(() => import('../pages/passwords/forgetPassword'));
const ResetPassword = lazy(() => import('../pages/passwords/resetPassword'));
const Dashboard = lazy(() => import('../pages/dashboard/dashboard'));
// const PageNotFound = lazy(() => import('../pages/404/pageNotFound'));
const ProfilePage = lazy(() => import('../pages/profile/profilepage'));
const ChangePassword = lazy(() => import('../pages/profile/changePassword'));
const ProfileEdit = lazy(() => import('../pages/profile/profileEdits'));
const Faq = lazy(() => import('../cms/faq'));
const ViewRole = lazy(() => import('../pages/roles/view'));
const ServiceProviderIndex = lazy(() => import('../pages/serviceprovider/index'));
const EditServiceProvider = lazy(() => import('../pages/serviceprovider/editServiceProvider'));
const PropertyOwnerIndex = lazy(() => import('../pages/propertyowner/index'));
const PropertyOwnerEdit = lazy(() => import('../pages/propertyowner/editPropertyOwner'));
const JobRequestIndex = lazy(() => import('../pages/jobrequest/index'));
const JobRequestView = lazy(() => import('../pages/jobrequest/view'));
const TaskIndex = lazy(() => import('../pages/task/index'));
const TaskView = lazy(() => import('../pages/task/view'));
const ActiveJobsView = lazy(() => import('../pages/activeJobs/view'));
const PropertyIndex = lazy(() => import('../pages/property/index'));
const PropertyEdit = lazy(() => import('../pages/property/propertyEdit'));
const PropertyView = lazy(() => import('../pages/property/view'));
const CategoryIndex = lazy(() => import('../pages/category/index'));
const CategoryCreate = lazy(() => import('../pages/category/createCategory'));
const CategoryEdit = lazy(() => import('../pages/category/editCategory'));
const SpaceCategoryIndex = lazy(() => import('../pages/spacecategory/index'));
const SpaceCategoryCreate = lazy(() => import('../pages/spacecategory/createSpaceCategory'));
const SpaceCategoryEdit = lazy(() => import('../pages/spacecategory/editSpaceCategory'));
const EquipmentCategoryIndex = lazy(() => import('../pages/equipmentcategory/index'));
const EquipmentCategoryCreate = lazy(() =>
  import('../pages/equipmentcategory/createEquipmentCategory')
);
const EquipmentCategoryEdit = lazy(() =>
  import('../pages/equipmentcategory/editEquipmentCategory')
);
const EquipmentSubCategoryIndex = lazy(() => import('../pages/equipmentsubcategory/index'));
const EquipmentSubCategoryCreate = lazy(() =>
  import('../pages/equipmentsubcategory/createEquipmentSubCategory')
);
const EquipmentSubCategoryEdit = lazy(() =>
  import('../pages/equipmentsubcategory/editEquipmentSubCategory')
);
const EquipmentIndex = lazy(() => import('../pages/equipment/index'));
const EquipmentCreate = lazy(() => import('../pages/equipment/createEquipment'));
const EquipmentEdit = lazy(() => import('../pages/equipment/editEquipment'));
const ServiceCategoryIndex = lazy(() => import('../pages/servicecategory/index'));
const ServiceCategoryCreate = lazy(() => import('../pages/servicecategory/createServiceCategory'));
const ServiceCategoryEdit = lazy(() => import('../pages/servicecategory/editServiceCategory'));
const ServiceSubCategoryIndex = lazy(() => import('../pages/servicesubcategory/index'));
const ServiceSubCategoryCreate = lazy(() =>
  import('../pages/servicesubcategory/createServiceSubCategory')
);
const ServiceSubCategoryEdit = lazy(() =>
  import('../pages/servicesubcategory/editServiceSubCategory')
);
const CertificationManagerIndex = lazy(() => import('../pages/certificationmanagement/index'));
const CertificationManagerView = lazy(() =>
  import('../pages/certificationmanagement/viewSchedule')
);
const CertificationManagerCreate = lazy(() =>
  import('../pages/certificationmanagement/addSchedule')
);
const CertificationManagerEdit = lazy(() =>
  import('../pages/certificationmanagement/editSchedule')
);
const UtilityCategoryIndex = lazy(() => import('../pages/utilitycategory/index'));
const UtilityCategoryCreate = lazy(() => import('../pages/utilitycategory/createUtilityCategory'));
const UtilityCategoryEdit = lazy(() => import('../pages/utilitycategory/editUtilityCategory'));
const RoomDirectionIndex = lazy(() => import('../pages/roomdirection/index'));
const RoomDirectionCreate = lazy(() => import('../pages/roomdirection/createRoomDirection'));
const RoomDirectionEdit = lazy(() => import('../pages/roomdirection/editRoomDirection'));
const RoomOrientationIndex = lazy(() => import('../pages/roomorientation/index'));
const RoomOrientationCreate = lazy(() => import('../pages/roomorientation/createRoomOrientation'));
const RoomOrientationEdit = lazy(() => import('../pages/roomorientation/editRoomOrientation'));
const RoomFloorIndex = lazy(() => import('../pages/roomfloor/index'));
const RoomFloorsCreate = lazy(() => import('../pages/roomfloor/createRoomFloor'));
const RoomFloorsEdit = lazy(() => import('../pages/roomfloor/editRoomFloor'));
const RoomWallIndex = lazy(() => import('../pages/roomwalls/index'));
const RoomWallCreate = lazy(() => import('../pages/roomwalls/createRoomWall'));
const RoomWallEdit = lazy(() => import('../pages/roomwalls/editRoomWall'));
const RoomRoofIndex = lazy(() => import('../pages/roomroofs/index'));
const RoomRoofCreate = lazy(() => import('../pages/roomroofs/createRoomRoof'));
const RoomRoofEdit = lazy(() => import('../pages/roomroofs/editRoomRoof'));
const ConnectedListDetailsView = lazy(() => import('../pages/users/connectedListDetails'));
const ConnectedListDetailsViewS = lazy(() => import('../pages/users/connectedListDetails'));
const ConnectedListDetailsViewP = lazy(() => import('../pages/users/connectedListDetails'));
const FloorDetailsView = lazy(() => import('../pages/floor/viewFloorDetail'));
const RoomDetailsView = lazy(() => import('../pages/floor/viewRoomDetail'));
const AssetDetailsView = lazy(() => import('../pages/floor/viewAssetDetail'));
const ServiceSettingsDetailsView = lazy(() => import('../pages/users/serviceSettingsDetails'));
const AssetDetailsEdit = lazy(() => import('../pages/floor/editAssetDetails'));
const RoomDetailsEdit = lazy(() => import('../pages/floor/editRoomDetails'));
const FloorDetailsEdit = lazy(() => import('../pages/floor/editFloorDetails'));

const ActiveJobs = lazy(() => import('../pages/activeJobs'));
const EditAppUser = lazy(() => import('../pages/users/editAppUser'));
const AgreementView = lazy(() => import('../pages/task/agreementView'));
const ViewPdf = lazy(() => import('../pages/users/pdfViewr'));
const SubscriptionIndex = lazy(() => import('../pages/subscription/index'));
const MessagesIndex = lazy(() => import('../pages/messages/index'));
const MessageView = lazy(() => import('../pages/messages/view'));
const SubscriptionCreate = lazy(() => import('../pages/subscription/createSubscription'));
const SubscriptionEdit = lazy(() => import('../pages/subscription/editSubscription'));
const SubscriptionView = lazy(() => import('../pages/subscription/viewSubDetails'));
const BroadcastIndex = lazy(() => import('../pages/broadcast/index'));
const BroadcastCreate = lazy(() => import('../pages/broadcast/create'));
const BroadcastEdit = lazy(() => import('../pages/broadcast/edit'));
const BroadcastView = lazy(() => import('../pages/broadcast/view'));
const FloorManagementIndex = lazy(() => import('../pages/floormanagement/index'));
const FloorManagementCreate = lazy(() => import('../pages/floormanagement/createFloorManagement'));
const FloorManagementEdit = lazy(() => import('../pages/floormanagement/editFloorManagement'));
const ServiceSettingsEdit = lazy(() => import('../pages/users/serviceSettingsEdit'));

const UserMessageView = lazy(() => import('../pages/users/viewMessage'));
const WaitlistIndex = lazy(() => import('../pages/waitlist/index'));

export default function Routers() {
  return (
    <Router>
      <Routes>
        <Route element={<OuterLayout />}>
          <Route
            exact
            path="/"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'relative',
                        textAlign: 'center',
                        top: '50%',
                        color: '#39979d',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#39979d',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/verify-email"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <UserVerification />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/register"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#39979d',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Register />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/forgetpassword"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: '50%',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '50%',
                        color: '#39979d',
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Forgetpassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reset/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route exact path="/" element={<Dashboard />} /> */}
          <Route
            exact
            path="/reset"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {' '}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
        </Route>
        <Route element={<Innerlayout />}>
          <Route
            exact
            path="/roles"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RolesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CreateRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/edit/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EditRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/viewdetails/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewRole />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/servicesettings/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ServiceSettingsEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <Dashboard />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/waitlist"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <WaitlistIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/serviceprovider"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ServiceProviderIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/serviceprovider/edit/:id"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EditServiceProvider />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/propertyowner"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PropertyOwnerIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/propertyowner/edit/:id"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PropertyOwnerEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* job-request */}
          <Route
            exact
            path="/jobrequest"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <JobRequestIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/jobrequest/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <JobRequestView />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* Active jobs / agreement */}
          <Route
            exact
            path="/active-jobs"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ActiveJobs />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/activejobs/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ActiveJobsView />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* task section */}

          <Route
            exact
            path="/task"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <TaskIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/task/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <TaskView />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/agreement/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <AgreementView />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* property section */}
          <Route
            exact
            path="/property"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PropertyIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/property/edit/:propertyid"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PropertyEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/property/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <PropertyView />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* category section */}
          <Route
            exact
            path="/category"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CategoryIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/category/create/"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CategoryCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/category/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CategoryEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* space category section */}
          <Route
            exact
            path="/spacecategory"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <SpaceCategoryIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/spacecategory/create"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <SpaceCategoryCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/spacecategory/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <SpaceCategoryEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* equipment category section */}
          <Route
            exact
            path="/equipmentcategory"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EquipmentCategoryIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/equipmentcategory/create"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EquipmentCategoryCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/equipmentcategory/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EquipmentCategoryEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* floor management section */}
          <Route
            exact
            path="/floormanagement"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <FloorManagementIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/floormanagement/create"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <FloorManagementCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/floormanagement/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <FloorManagementEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* equipment sub category section */}
          <Route
            exact
            path="/equipmentsubcategory"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EquipmentSubCategoryIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/equipmentsubcategory/create"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EquipmentSubCategoryCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/equipmentsubcategory/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EquipmentSubCategoryEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* equipment section */}
          <Route
            exact
            path="/equipment"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EquipmentIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/equipment/create"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EquipmentCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/equipment/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EquipmentEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* service category section */}
          <Route
            exact
            path="/servicecategory"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ServiceCategoryIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/servicecategory/create"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ServiceCategoryCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/servicecategory/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ServiceCategoryEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* service sub category section */}
          <Route
            exact
            path="/servicesubcategory"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ServiceSubCategoryIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/servicesubcategory/create"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ServiceSubCategoryCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/servicesubcategory/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ServiceSubCategoryEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* certificate management category section */}
          <Route
            exact
            path="/certificationmanagement"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CertificationManagerIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/certificationmanagement/view/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CertificationManagerView />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/certificationmanagement/create/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CertificationManagerCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/user-message/view/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserMessageView />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/certificationmanagement/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <CertificationManagerEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* utility management category section */}
          <Route
            exact
            path="/utilitycategory"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UtilityCategoryIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/utilitycategory/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UtilityCategoryCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/utilitycategory/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UtilityCategoryEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* room direction  section */}
          <Route
            exact
            path="/roomdirection"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomDirectionIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roomdirection/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomDirectionCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roomdirection/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomDirectionEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* room orientation  section */}
          <Route
            exact
            path="/roomorientation"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomOrientationIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roomorientation/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomOrientationCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roomorientation/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomOrientationEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* room floors  section */}
          <Route
            exact
            path="/roomfloor"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomFloorIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roomfloor/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomFloorsCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roomfloor/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomFloorsEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* room walls  section */}
          <Route
            exact
            path="/roomwall"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomWallIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roomwall/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomWallCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roomwall/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomWallEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          {/* room roof  section */}
          <Route
            exact
            path="/roomroof"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomRoofIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roomroof/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomRoofCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roomroof/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomRoofEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/subscription"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <SubscriptionIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/subscription/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <SubscriptionView />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/subscription/create"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <SubscriptionCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/subscription/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <SubscriptionEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/messages"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <MessagesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/broadcast"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <BroadcastIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/broadcast/create"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <BroadcastCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/broadcast/edit/:id"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <BroadcastEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/broadcast/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <BroadcastView />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/messages/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div style={{ textAlign: 'center', marginTop: '400px', color: '#39979d' }}>
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <MessageView />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* connected list details route */}
          <Route
            exact
            path="/appuser/connectedlist/details/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ConnectedListDetailsView />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/serviceprovider/connectedlist/details/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ConnectedListDetailsViewS />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/propertyowner/connectedlist/details/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ConnectedListDetailsViewP />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/floor/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <FloorDetailsView />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/room/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomDetailsView />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/asset/viewdetails/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <AssetDetailsView />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/asset/editdetails/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <AssetDetailsEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/room/editdetails/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <RoomDetailsEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/floor/editdetails/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <FloorDetailsEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/servicesettings/details/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ServiceSettingsDetailsView />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/user/view-pdf/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewPdf />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/user/viewdetails/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <ViewUsers />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/appuser/edit/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <EditAppUser />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/appuser"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {' '}
                  <UserIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route element={<SettingsLayout />}>
            <Route
              exact
              path="/settings"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <Settings />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/preferences"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <Preferences />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/api"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <SettingsApi />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
          <Route element={<ProfileLayout />}>
            <Route
              exact
              path="/profile"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <ProfilePage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/profile-edit"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <ProfileEdit />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/cms"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted style={{ marginTop: '250px', marginLeft: '300px' }} />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <Faq />
                  </ProtectedRoute>
                </Suspense>
              }
            />

            <Route
              exact
              path="/changePassword"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {' '}
                    <ChangePassword />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          exact
          path="*"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                  />
                </div>
              }
            >
              {' '}
              <PageNotFound />
            </Suspense>
          }
        />
        <Route
          exact
          path="/permission"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{ marginTop: '250px', marginLeft: '300px', color: '#39979d' }}
                  />
                </div>
              }
            >
              <ProtectedRoute>
                {' '}
                <PermissionDenied />
              </ProtectedRoute>
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

// eslint-disable-next-line react/prop-types
function ProtectedRoute({ children }) {
  if (document.cookie && getCookies('Token')) {
    if (getCookies('USERPERMISSION')) {
      return children;
    }
    removeCookies('Token');
    removeCookies('refreshToken');
    sessionStorage.setItem('cookiesCleared', true);
    return <Navigate to="/login" />;
  }
  sessionStorage.setItem('cookiesCleared', true);
  return <Navigate to="/login" />;
}

// eslint-disable-next-line react/prop-types
function UnProtectedRoute({ children }) {
  return getCookies('Token') ? <Navigate to="/dashboard" /> : children;
}
